import format from 'date-fns/format'

import { FC, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import DataGrid from '@rfh/ui/components/DataGrid/DataGrid'
import { Row } from '@rfh/ui/components/DataGrid/DataGrid'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import {
  FlowDialog,
  FlowDialogContent,
  FlowDialogProps,
  FlowDialogTitle,
} from 'src/components/FlowDialog'
import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { FustTransaction } from 'src/types/transaction'

export type MobileDetailsDialogProps = FlowDialogProps & {
  transaction: FustTransaction
  onActionHandler?: MouseEventHandler
}
export const TransactionsMobileDetailDialog: FC<MobileDetailsDialogProps> = ({
  transaction,
  onActionHandler,
  ...rest
}) => {
  const { t } = useTranslation()
  const dataGridLayout = [4, 8]
  const rows: Row[] = [
    {
      values: [],
    },
    {
      values: [t('transactions.header.customer'), transaction.klantNaam],
    },
    {
      values: [t('location'), transaction.klantLocatie],
    },
    {
      values: [t('countTable.headings.transId'), transaction.id],
    },
    {
      values: [
        t('countTable.headings.createdOn'),
        format(new Date(transaction.createdOn), 'dd/MM HH:mm'),
      ],
    },
    {
      values: [t('countTable.headings.transNumb'), transaction.vervoerderNummer],
    },
    {
      values: [t('countTable.headings.notes'), transaction.externeNotitie],
    },
  ]
  return (
    <FlowDialog fullScreen {...rest}>
      <FlowDialogTitle>{t('dialog.transactionDetails')}</FlowDialogTitle>
      <FlowDialogContent>
        <FlowView>
          <FlowViewContent>
            <Stack direction={'column'} gap={2}>
              <RfhTypography variant={'subtitle1'}>
                {t('dialog.detailsOverview')}
              </RfhTypography>
              <DataGrid
                label='TransactionMobileDetails DataGrid'
                layout={dataGridLayout}
                rows={rows}
              />
            </Stack>
          </FlowViewContent>
          <FlowViewActions>
            <Button
              fullWidth
              variant={'contained'}
              onClick={event => onActionHandler && onActionHandler(event)}
            >
              {t('button.continue')}
            </Button>
          </FlowViewActions>
        </FlowView>
      </FlowDialogContent>
    </FlowDialog>
  )
}
